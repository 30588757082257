import Vue from 'vue'
import VueRouter from 'vue-router'
// import BrochureSite from '@/views/BrochureSite/BrochureSite.vue'
import PromoHome from '@/views/PromoSite/HomeView.vue'
import PagePlaceholder from '@/views/PagePlaceholder'
import SignUp from '@/views/SignUp'
// import AuthenticatedRedirect from '@/views/AuthenticatedRedirect'
import UserHomeRedirect from '@/views/UserHomeRedirect'
import PreLogout from '@/views/PreLogout'
import SessionExpired from '@/views/SessionExpired'
import { authGuard } from '@/auth'

Vue.use(VueRouter)

const routes = [
  // START PROMOTIONAL ROUTES ----------------------------------------
  {
    path: '/',
    name: 'HomeView',
    props: true,
    component: PromoHome,
    meta: { label: 'Home', stickyNav: false, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'home' } }
  },
  {
    path: '/features',
    name: 'FeaturesView',
    props: true,
    component: () => import('@/views/PromoSite/FeaturesView.vue'),
    meta: { label: 'Features', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'features' } }
  },
  {
    path: '/activities',
    name: 'ActivitiesView',
    props: true,
    component: () => import('@/views/PromoSite/ActivitiesView.vue'),
    meta: { label: 'Activities', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activities' } }
  },
  {
    path: '/activities/activity-demo/:playKey',
    name: 'ActivityDetailView',
    props: true,
    component: () => import('@/views/PromoSite/ActivityDetailView.vue'),
    meta: { label: 'Activity', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-demo' } }
  },
  {
    path: '/plans',
    name: 'PricingView',
    props: true,
    component: () => import('@/views/PromoSite/PricingView.vue'),
    meta: { label: 'Plans', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'plans' } }
  },
  {
    path: '/plans/:tag',
    name: 'PricingView',
    props: true,
    component: () => import('@/views/PromoSite/PricingView.vue'),
    meta: { label: 'Plans', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'plans' } }
  },
  {
    path: '/about',
    name: 'AboutView',
    props: true,
    component: () => import('@/views/PromoSite/AboutView.vue'),
    meta: { label: 'About', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'about' } }
  },
  {
    path: '/data',
    name: 'DataView',
    props: true,
    component: () => import('@/views/PromoSite/DataView.vue'),
    meta: { label: 'Data', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'about' } }
  },
  {
    path: '/partners/:tag',
    name: 'PartnerView',
    props: true,
    component: () => import('@/views/PromoSite/PartnerView.vue'),
    meta: { label: 'Partner', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'bernell' } }
  },
  {
    path: '/home-program',
    name: 'HomeProgramView',
    props: true,
    component: () => import('@/views/PromoSite/HomeProgramView.vue'),
    meta: { label: 'Home Program', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'home-program' } }
  },
  {
    path: '/brain-rehab',
    name: 'BrainRehab',
    props: true,
    component: () => import('@/views/PromoSite/BrainRehab.vue'),
    meta: { label: 'brain rehab', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'brain-rehab' } }
  },
  {
    path: '/brain-rehab-home',
    name: 'BrainRehabHome',
    props: true,
    component: () => import('@/views/PromoSite/BrainRehabHome.vue'),
    meta: { label: 'Brain Rehab at Home', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'brain-rehab-home' } }
  },
  {
    path: '/brain-rehab-registration',
    name: 'BrainRehabRegistrationView',
    props: true,
    component: () => import('@/views/PromoSite/BrainRehabRegistrationView.vue'),
    meta: { label: 'Brain Rehab Trial', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'brain-rehab-trial' } }
  },
  {
    path: '/todos',
    name: 'TodosView',
    props: true,
    component: () => import('@/views/PromoSite/TodosView.vue'),
    meta: { label: 'Todos', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'todos' } }
  },
  {
    path: '/bookstore',
    name: 'StoreView',
    props: true,
    component: () => import('@/views/PromoSite/StoreView.vue'),
    meta: { label: 'Book', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'book' } }
  },
  {
    path: '/bookstore/see-and-say',
    name: 'BookSeeAndSayView',
    props: true,
    component: () => import('@/views/PromoSite/BookSeeAndSayView.vue'),
    meta: { label: 'Book', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'book' } }
  },
  {
    path: '/bookstore/see-and-say-flipbook',
    name: 'SeeAndSayFlipbookView',
    props: true,
    component: () => import('@/views/PromoSite/SeeAndSayFlipbookView.vue'),
    meta: { label: 'Flipbooks', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'flipbooks' } }
  },
  {
    path: '/bookstore/ippra',
    name: 'BookIppraView',
    props: true,
    component: () => import('@/views/PromoSite/BookIppraView.vue'),
    meta: { label: 'Book', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'book' } }
  },
  {
    path: '/unverified',
    name: 'BrochureUnverified',
    props: true,
    component: () => import('@/views/PromoSite/UnverifiedView.vue'),
    meta: { label: 'Unverified', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'unverified' } }
  },
  {
    path: '/verification-pending',
    name: 'BrochurePending',
    props: true,
    component: () => import('@/views/PromoSite/VerificationPendingView.vue'),
    meta: { label: 'Verification Pending', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'unverified' } }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    props: true,
    component: () => import('@/views/PrivacyPolicy.vue'),
    meta: { label: 'Privacy Policy', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'privacy-policy' } }
  },
  {
    path: '/frequently-asked-questions',
    name: 'FaqView',
    props: true,
    component: () => import('@/views/FaqView.vue'),
    meta: { label: 'FAQ', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'Frequently Asked Questions' } }

  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    props: true,
    component: () => import('@/views/TermsAndConditions.vue'),
    meta: { label: 'Terms and Conditions', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'terms-and-conditions' } }
  },
  {
    path: '/contact',
    name: 'ContactView',
    props: true,
    component: () => import('@/views/PromoSite/ContactView.vue'),
    meta: { label: 'Contact', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'contact' } }
  },
  {
    path: '/free-trial',
    name: 'BrochureFreeTrial',
    props: true,
    component: () => import('@/views/PromoSite/FreeTrialView.vue'),
    meta: { label: 'Free Trial', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'free-trial' } }
  },
  {
    path: '/play-activity-demo-configuration',
    name: 'PlayActivityDemoConfiguration',
    props: true,
    component: () => import('@/views/PlayActivityDemoConfiguration')
  },
  // END PROMOTIONAL ROUTES ----------------------------------------
  {
    path: '/user-home-redirect',
    name: 'UserHomeRedirect',
    component: UserHomeRedirect,
    beforeEnter: authGuard
  },
  {
    path: '/pre-logout',
    name: 'PreLogout',
    component: PreLogout
  },
  {
    path: '/session-expired',
    name: 'SessionExpired',
    component: SessionExpired
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: SignUp
  },
  {
    path: '/raw-user',
    name: 'RawUser',
    props: true,
    component: () => import('@/views/RawUser.vue'),
    beforeEnter: authGuard,
    // Has correct permissions
    meta: { needsPermission: ['p:super-admin', 'm:clinic', 'm:patient'], label: 'raw-user', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'raw-user' } }
  },
  {
    path: '/admin/application-manager',
    name: 'ApplicationManager',
    props: true,
    component: () => import('@/views/admin/applicationManager/ApplicationManager'),
    beforeEnter: authGuard,
    // Has correct permissions
    meta: { needsPermission: ['p:super-admin'], label: 'Application Manager', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'application-manager' } }
  },
  {
    path: '/admin/application-manager/app-route/:appRouteId',
    name: 'AppRouteDetail',
    props: true,
    component: () => import('@/views/admin/applicationManager/AppRouteDetail'),
    beforeEnter: authGuard,
    // Has correct permissions
    meta: { needsPermission: ['p:super-admin'], label: 'App Route', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'appRouteId' } }
  },
  {
    path: '/admin/notifications',
    name: 'NotificationManager',
    props: true,
    component: () => import('@/views/admin/Notifications/NotificationManager'),
    beforeEnter: authGuard,
    // works for super-admin
    meta: { needsPermission: ['p:super-admin'], label: 'Notification Manager', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'notification-manager' } }
  },
  {
    path: '/admin/notifications/create',
    name: 'NotificationBuilder',
    props: true,
    component: () => import('@/views/admin/Notifications/NotificationBuilder'),
    beforeEnter: authGuard,
    // works for super-admin
    meta: { needsPermission: ['p:super-admin'], label: 'Create Notification', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'create-notification' } }
  },
  {
    path: '/admin/notifications/edit/:id',
    name: 'EditNotification',
    props: true,
    component: () => import('@/views/admin/Notifications/EditNotification'),
    beforeEnter: authGuard,
    // works for super-admin
    meta: { needsPermission: ['p:super-admin'], label: 'Edit Notification', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'edit-notification' } }
  },
  // {
  //   path: '/admin/old-customers',
  //   name: 'OldCustomers',
  //   props: true,
  //   component: () => import('@/views/admin/oldCustomers/OldDataImport'),
  //   beforeEnter: authGuard,
  //   meta: { needsPermission: ['p:super-admin'], label: 'Old Customers', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'old-customers' } }
  // },
  // {
  //   path: '/admin/old-customer/:companyId',
  //   name: 'OldCustomerDetail',
  //   props: true,
  //   component: () => import('@/views/admin/oldCustomers/OldCustomerDetail'),
  //   beforeEnter: authGuard,
  //   meta: { needsPermission: [], label: 'Old Customer Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'old-customer-detail' } }
  // },
  {
    path: '/help-center-admin',
    name: 'HelpCenterAdmin',
    props: true,
    component: () => import('@/views/clinic/HelpCenterAdmin'),
    beforeEnter: authGuard,
    // works for super-admin
    meta: { needsPermission: ['p:super-admin'], label: 'Help CenterAdmin', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'admin-help-center' } }
  },
  {
    path: '/help-center-admin/help-request/:userHelpRequestId',
    name: 'AdminHelpRequest',
    props: true,
    component: () => import('@/views/clinic/HelpRequest'),
    beforeEnter: authGuard,
    // works for super-admin
    meta: { needsPermission: ['p:super-admin'], label: 'Admin Help Request', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'admin-help-request' } }
  },
  {
    path: '/help-center',
    name: 'HelpCenter',
    props: true,
    component: () => import('@/views/clinic/HelpCenter'),
    beforeEnter: authGuard,
    // works for clinicians
    meta: { needsPermission: ['m:clinic'], label: 'Help Center', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'help-center' } }
  },
  {
    path: '/help-center/help-request/:userHelpRequestId',
    name: 'HelpRequest',
    props: true,
    component: () => import('@/views/clinic/HelpRequest'),
    beforeEnter: authGuard,
    // works for clinicians
    meta: { needsPermission: ['m:clinic'], label: 'Help Request', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'help-request' } }
  },
  // {
  //   path: '/admin/old-user/:memberId',
  //   name: 'OldUserDetail',
  //   props: true,
  //   component: () => import('@/views/admin/oldCustomers/OldUserDetail.vue'),
  //   beforeEnter: authGuard,
  //   meta: { needsPermission: [], label: 'Old User Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'old-user-detail' } }
  // },
  // {
  //   path: '/admin/old-users',
  //   name: 'OldUsers',
  //   props: true,
  //   component: () => import('@/views/admin/oldCustomers/OldUsers.vue'),
  //   beforeEnter: authGuard,
  //   meta: { needsPermission: [], label: 'Old Users', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'old-users' } }
  // },
  {
    path: '/admin/activity-session-explorer',
    name: 'ActivitySessionExplorer',
    props: true,
    component: () => import('@//views/admin/ovbDashboard/components/ActivitySessionExplorer/ActivitySessionExplorer.vue'),
    beforeEnter: authGuard,
    // good
    meta: { needsPermission: ['p:super-admin'], label: 'Activity Sessions', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-session-explorer' } }
  },
  {
    path: '/admin/activity-session-explorer/session-detail/:sessionId',
    name: 'AdminActivitySessionViewer',
    props: true,
    component: () => import('@/views/admin/ovbDashboard/components/ActivitySessionExplorer/ActivitySessionViewer.vue'),
    beforeEnter: authGuard,
    // good
    meta: { needsPermission: ['p:super-admin'], label: 'Activity Session', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-session' } }
  },
  {
    path: '/clinic/activity-sessions',
    name: 'ClinicSessionManager',
    props: true,
    component: () => import('@/views/admin/clinicSessionManager/ClinicSessionManager.vue'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic', 'p:super-admin', 'p:support'], label: 'Activity Sessions', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-sessions' } }
  },
  {
    path: '/clinic/assignment-review',
    name: 'ActivityAssignmentReview',
    props: true,
    component: () => import('@/views/admin/activityAssignmentReview/ActivityAssignmentReview.vue'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic', 'p:super-admin', 'p:support'], label: 'Assignment Review', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'assignment-review' } }
  },
  {
    path: '/clinic/activity-sessions/session-detail/:sessionId',
    name: 'ClinicActivitySessionViewer',
    props: true,
    component: () => import('@/views/admin/clinicSessionManager/ActivitySessionViewer.vue'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic', 'p:super-admin', 'p:support'], label: 'Activity Session', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'session-detail' } }
  },
  {
    path: '/admin/saas-agreement-manager',
    name: 'EulaManager',
    props: true,
    component: () => import('@/views/admin/eula/EulaManager'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['p:super-admin'], label: 'SaaS Agreement Mgr', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'saas-agreement-manager' } }
  },
  {
    path: '/saas-agreement',
    name: 'SignAppEula',
    props: true,
    component: () => import('@/views/admin/eula/SignAppEula'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'SaaS Agreement', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'saas-agreement' } }
  },
  {
    path: '/signed-eula/:signedEulaId',
    name: 'SignedEulaDetail',
    props: true,
    component: () => import('@/views/admin/eula/SignedEulaDetail'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic', 'p:super-admin'], label: 'Signed SaaS Agreement', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'signed-saas-agreement' } }
  },
  {
    path: '/admin/license-pack',
    name: 'LicensePackManager',
    props: true,
    component: () => import('@/views/admin/licensePackManager/LicensePackManager'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'License Pack', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'license-pack' } }
  },
  {
    path: '/admin/license-pack/:licensePackId',
    name: 'LicensePack',
    props: true,
    component: () => import('@/views/admin/licensePackManager/LicensePack'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'License Pack Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'license-pack-details' } }
  },
  {
    path: '/admin/app-tenant-manager',
    name: 'AppTenantManager',
    props: true,
    component: () => import('@/views/admin/appTenantManager/AppTenantManager'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'App Tenant Manager', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'app-tenant-manager' } }
  },
  {
    path: '/admin/app-tenant-manager/app-tenant/:appTenantName/:appTenantId',
    name: 'AppTenant',
    props: true,
    component: () => import('@/views/admin/appTenantManager/AppTenant'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin', 'p:support'], label: 'App Tenant Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'appTenantName' } }
  },
  {
    path: '/admin/workflow',
    name: 'ProjectManager',
    props: true,
    component: () => import('@/views/admin/projectManager/ProjectManager'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'Projects', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'projects' } }
  },
  {
    path: '/admin/workflow/template/:projectId',
    name: 'ProjectManagerTemplateDetail',
    props: true,
    component: () => import('@/views/admin/projectManager/ProjectManagerTemplateDetail'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'Project Template Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'project-template' } }
  },
  {
    path: '/admin/workflow/instance/:projectId',
    name: 'ProjectManagerInstanceDetail',
    props: true,
    component: () => import('@/views/admin/projectManager/ProjectManagerInstanceDetail'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'Project Instance Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'project-instance' } }
  },
  {
    path: '/admin/images',
    name: 'ImageManager',
    props: true,
    component: () => import('@/views/admin/imageManager/ImageManager'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'Images', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'images' } }
  },
  {
    path: '/admin/subscriptions',
    name: 'MySubscription',
    props: true,
    component: () => import('@/views/admin/appTenantManager/MySubscription'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin', 'm:clinic-admin'], label: 'Subscriptions', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'subscriptions' } }
  },
  {
    path: '/admin/billing',
    name: 'Billing',
    props: true,
    component: () => import('@/views/admin/appTenantManager/Billing'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'Billing', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'billing' } }
  },
  {
    path: '/admin/settings',
    name: 'AppTenantSettings',
    props: true,
    component: () => import('@/views/admin/appTenantManager/AppTenantSettings'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'Settings', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'settings' } }
  },
  {
    path: '/admin/users',
    name: 'AppTenantAppUsers',
    props: true,
    component: () => import('@/views/admin/appTenantManager/AppTenantAppUsers'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin', 'p:support'], label: 'Users', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'users' } }
  },
  {
    path: '/admin/guest-users',
    name: 'GuestUsers',
    props: true,
    component: () => import('@/views/admin/appTenantManager/GuestUsers'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'Guest Users', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'guest-users' } }
  },
  {
    path: '/admin/users/:userName/:appUserId',
    name: 'AppUser',
    props: true,
    component: () => import('@/views/admin/appTenantManager/AppUser'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin', 'p:support'], label: 'App User Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'userName' } }
  },
  {
    path: '/admin/guest-users/:userName/:appUserId',
    name: 'GuestUser',
    props: true,
    component: () => import('@/views/admin/appTenantManager/AppUser'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'App User Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'userName' } }
  },
  {
    path: '/admin/app-tenant-subscription/:appTenantSubscriptionId',
    name: 'AppTenantSubscription',
    props: true,
    component: () => import('@/views/admin/appTenantSubscription/AppTenantSubscription'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin', 'p:support'], label: 'App Tenant Subscription', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'appTenantSubscriptionId' } }
  },
  {
    path: '/admin/license/:licenseId',
    name: 'LicenseDetail',
    props: true,
    component: () => import('@/views/admin/appTenantManager/LicenseDetail'),
    beforeEnter: authGuard,
    // anything starting with /admin should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin', 'p:support'], label: 'License Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'licenseId' } }
  },
  {
    path: '/ovb-dashboard',
    name: 'OvbDashboard',
    props: true,
    component: () => import('@/views/admin/ovbDashboard/OvbDashboard'),
    beforeEnter: authGuard,
    // p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'OVB Dashboard', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'ovb-dashboard' } }
  },
  {
    path: '/activity-errors',
    name: 'ActivityErrors',
    props: true,
    component: () => import('@/views/admin/ovbDashboard/ActivityErrors'),
    beforeEnter: authGuard,
    // should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'Activity Errors', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-errors' } }
  },
  {
    // WHERE IS THIS USED?????
    path: '/messaging-manager',
    name: 'MessagingManager',
    props: true,
    component: () => import('@/views/admin/messaging/MessagingManager'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['p:super-admin'], label: 'Messaging Manager', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'messaging-manager' } }
  },
  {
    // WHERE IS THIS USED?????
    path: '/help-topic-manager',
    name: 'HelpTopicManager',
    props: true,
    component: () => import('@/views/admin/helpTopicManager/HelpTopicManager'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['p:super-admin'] }
  },
  {
    // WHERE IS THIS USED?????
    path: '/help-topic-manager/:helpTopicId/',
    name: 'HelpTopicManagerDetail',
    props: true,
    component: () => import('@/views/admin/helpTopicManager/HelpTopicManagerDetail'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['p:super-admin'] }
  },
  {
    path: '/admin/manager-manager',
    name: 'ManagerManager',
    props: true,
    component: () => import('@/views/admin/managerManager/ManagerManager'),
    beforeEnter: authGuard,
    // should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'Manager Manager', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'manager-manager' } }
  },
  {
    path: '/admin/activity-language-manager-manager',
    name: 'ActivityLanguageManagerManager',
    props: true,
    component: () => import('@/views/admin/activityLanguageManager/ActivityLanguageManagerManager'),
    beforeEnter: authGuard,
    // should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin', 'p:manage-game-language'], label: 'Activity Language Manager Manager', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-language-manager-manager' } }
  },
  {
    path: '/admin/activity-language-manager',
    name: 'ActivityLanguageManager',
    props: true,
    component: () => import('@/views/admin/activityLanguageManager/ActivityLanguageManager'),
    beforeEnter: authGuard,
    // should be p:super-admin, so good
    meta: { needsPermission: ['p:manage-game-language', 'p:super-admin'], label: 'Activity Language Manager', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-language-manager' } }
  },
  {
    path: '/admin/demo-center',
    name: 'DemoCenter',
    props: true,
    component: () => import('@/views/admin/demoCenter/DemoCenter'),
    beforeEnter: authGuard,
    // ??????? kevin set this one, so fine??
    meta: { needsPermission: ['p:demo', 'p:super-admin'], label: 'Demo Center', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'demo-center' } }
  },
  {
    path: '/admin/support-center',
    name: 'SupportCenter',
    props: true,
    component: () => import('@/views/admin/supportCenter/SupportCenter'),
    beforeEnter: authGuard,
    // ??????? kevin set this one, so fine??
    meta: { needsPermission: ['p:app-tenant-group-admin', 'p:super-admin'], label: 'Support Center', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'support-center' } }
  },
  {
    path: '/admin/app-tenant-group-manager/:appTenantGroupId',
    name: 'AppTenantGroupManagerDetail',
    props: true,
    component: () => import('@/views/admin/appTenantGroupManager/AppTenantGroupManagerDetail'),
    beforeEnter: authGuard,
    // should be p:super-admin, so good
    meta: { needsPermission: ['p:super-admin'], label: 'App Tenant Group Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'appTenantGroupId' } }
  },
  {
    path: '/activity-language-manager/activity-detail/:playKey',
    name: 'ActivityLanguageManagerDetail',
    props: true,
    component: () => import('@/views/admin/activityLanguageManager/ActivityLanguageManagerDetail'),
    beforeEnter: authGuard,
    // ??????? kevin set this one, so fine??
    meta: { needsPermission: ['p:manage-game-language', 'p:super-admin'], label: 'Activity Language Manager Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-language-manager-detail' } }
  },
  {
    path: '/activity-manager',
    name: 'ActivityManager',
    props: true,
    component: () => import('@/views/admin/activityManager/ActivityManager'),
    beforeEnter: authGuard,
    // ??????? kevin set this one, so fine??
    meta: { needsPermission: ['p:super-admin', 'p:ovb-activity-developer'], label: 'Activity Manager', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-manager' } }
  },
  {
    path: '/activity-manager/activity-detail/:activityName/:playKey/',
    name: 'ActivityDetailByPlayKey',
    props: true,
    component: () => import('@/views/admin/activityManager/ActivityDetailByPlayKey'),
    beforeEnter: authGuard,
    // ??????? kevin set this one, so fine??
    meta: { needsPermission: ['p:super-admin', 'p:ovb-activity-developer'], label: 'Activity Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'activityName' } }
  },
  {
    path: '/activity-manager/activity-version-detail/:gameId/',
    name: 'ActivityDetailById',
    props: true,
    component: () => import('@/views/admin/activityManager/ActivityDetailById'),
    beforeEnter: authGuard,
    // ??????? kevin set this one, so fine??
    meta: { needsPermission: ['p:super-admin', 'p:ovb-activity-developer'], label: 'Activity Version', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-version' } }
  },
  {
    path: '/activity-manager/session-tester/:sessionId/',
    name: 'ActivitySessionTester',
    props: true,
    component: () => import('@/views/admin/activityManager/ActivitySessionTester'),
    beforeEnter: authGuard,
    // ??????? kevin set this one, so fine??
    meta: { needsPermission: ['p:super-admin', 'p:ovb-activity-developer'], label: 'Session Tester', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'session-tester' } }
  },
  {
    path: '/activity-categorization',
    name: 'ActivityCategorizationManager',
    props: true,
    component: () => import('@/views/admin/activityCategorization/ActivityCategorizationManager'),
    beforeEnter: authGuard,
    // permissions good, should change URL to /admin/etc
    meta: { needsPermission: ['p:super-admin'], label: 'Activity Categorization', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-categorization' } }
  },
  {
    path: '/activity-categorization/library/:libraryId/',
    name: 'LibraryDetail',
    props: true,
    component: () => import('@/views/admin/activityCategorization/LibraryDetail'),
    beforeEnter: authGuard,
    // permissions good, should change URL to /admin/etc
    meta: { needsPermission: ['p:super-admin'], label: 'Library Detail', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'library-detail' } }
  },
  {
    path: '/activity-categorization/therapy-category/:therapyCategoryKey/',
    name: 'TherapyCategoryDetail',
    props: true,
    component: () => import('@/views/admin/activityCategorization/TherapyCategoryDetail'),
    beforeEnter: authGuard,
    // permissions good, should change URL to /admin/etc
    meta: { needsPermission: ['p:super-admin'], label: 'Therapy Category', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'therapy-category' } }

  },
  {
    path: '/activity-categorization/cognitive-domain/:cognitiveDomainKey/',
    name: 'CognitiveDomainDetail',
    props: true,
    component: () => import('@/views/admin/activityCategorization/CognitiveDomainDetail'),
    beforeEnter: authGuard,
    // permissions good, should change URL to /admin/etc
    meta: { needsPermission: ['p:super-admin'], label: 'Cognitive Domain', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'cognitive-domain' } }
  },
  {
    path: '/activity-configuration-library',
    name: 'ActivityConfigurationLibrary',
    props: true,
    component: () => import('@/views/admin/activityConfiguration/ActivityConfigurationLibrary'),
    beforeEnter: authGuard,
    // permissions good, todo: should change URL to /admin/etc
    meta: { needsPermission: ['p:super-admin'], label: 'Activity Configuration Library', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-configuration-library' } }
  },
  {
    path: '/activity-configuration/:playKey/:libraryId',
    name: 'LibraryGameConfigurationSummary',
    props: true,
    component: () => import('@/views/admin/activityConfiguration/LibraryGameConfigurationSummary'),
    beforeEnter: authGuard,
    // permissions good, todo: should change URL to /admin/etc
    meta: { needsPermission: ['p:super-admin'], label: 'Activity Configuration', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-configuration' } }
  },
  {
    path: '/admin/video-manager',
    name: 'WeMoveManager',
    props: true,
    component: () => import('@/views/admin/weMoveManager/WeMoveManager'),
    beforeEnter: authGuard,
    // permissions good
    meta: { needsPermission: ['p:super-admin'], label: 'Video Manager', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'video-manager' } }
  },
  {
    path: '/admin/video-manager/:weMoveKey/',
    name: 'WeMoveManagerDetail',
    props: true,
    component: () => import('@/views/admin/weMoveManager/WeMoveManagerDetail'),
    beforeEnter: authGuard,
    // permissions good
    meta: { needsPermission: ['p:super-admin'], label: 'Video Detail', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'weMoveKey' } }
  },
  {
    path: '/admin/worksheet-manager',
    name: 'WorksheetManager',
    props: true,
    component: () => import('@/views/admin/worksheetManager/WorksheetManager'),
    beforeEnter: authGuard,
    // permissions good
    meta: { needsPermission: ['p:super-admin'], label: 'Worksheet Manager', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'worksheet-manager' } }
  },
  {
    path: '/admin/worksheet-manager/:worksheetKey/',
    name: 'WorksheetManagerDetail',
    props: true,
    component: () => import('@/views/admin/worksheetManager/WorksheetManagerDetail'),
    beforeEnter: authGuard,
    // permissions good
    meta: { needsPermission: ['p:super-admin'], label: 'Worksheet', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'worksheetKey' } }
  },
  {
    path: '/clinic-playlists',
    name: 'ClinicGamePlayLists',
    props: true,
    component: () => import('@/views/clinic/ClinicGamePlayLists'),
    beforeEnter: authGuard,
    // permissions good
    meta: { needsPermission: ['m:clinic', 'p:support'], label: 'Clinic Playlists', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'clinic-playlists' } }
  },
  {
    path: '/clinic-playlists/:playlistName/:gamePlayListId',
    name: 'ManageClinicActivityPlaylist',
    props: true,
    component: () => import('@/views/clinic/ManageClinicActivityPlaylist'),
    beforeEnter: authGuard,
    // permissions good
    meta: { needsPermission: ['m:clinic'], label: 'Clinic Playlist', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'playlistName' } }
  },
  // {
  //   path: '/silver/gameroom',
  //   name: 'GameRoomSilver',
  //   props: true,
  //   component: () => import('@/views/silver/GameRoomSilver'),
  //   beforeEnter: authGuard
  // },
  // {
  //   path: '/silver/gameroom/:activityKey',
  //   name: 'ActivitySilver',
  //   props: true,
  //   component: () => import('@/views/silver/ActivitySilver'),
  //   beforeEnter: authGuard
  // },
  // {
  //   path: '/silver/gameroom/:activityKey/:playKey',
  //   name: 'GameSilver',
  //   props: true,
  //   component: () => import('@/views/silver/GameSilver'),
  //   beforeEnter: authGuard
  // },
  {
    path: '/clinic-trial/clinic-trial-home',
    name: 'ClinicTrial',
    props: true,
    component: PagePlaceholder,
    beforeEnter: authGuard
    // todo: clinic
  },
  {
    path: '/clinic-trial/trial-gameplay',
    name: 'TrialGameplay',
    props: true,
    component: PagePlaceholder,
    beforeEnter: authGuard
    // todo: clinic
  },
  {
    path: '/clinic-trial/pricing',
    name: 'ClinicPricing',
    props: true,
    component: PagePlaceholder,
    beforeEnter: authGuard
    // todo: clinic
  },
  {
    path: '/clinic-trial/purchase-upgrade',
    name: 'PurchaseUpgrade',
    props: true,
    component: PagePlaceholder,
    beforeEnter: authGuard
    // todo: clinic
  },
  {
    path: '/activity-explorer',
    name: 'ActivityExplorer',
    props: true,
    component: () => import('@/views/clinic/ActivityExplorer'),
    beforeEnter: authGuard,
    // permissions good
    meta: { needsPermission: ['m:clinic', 'p:super-admin'], label: 'Activity Explorer', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'activity-explorer' } }
  },
  {
    path: '/activity-explorer/:activityName/:playKey',
    name: 'ActivityExplorerDetail',
    props: true,
    component: () => import('@/views/clinic/ActivityExplorerDetail'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic', 'p:super-admin'], label: 'Activity Detail', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'activityName' } }
  },
  {
    path: '/worksheet-explorer',
    name: 'WorksheetExplorer',
    props: true,
    component: () => import('@/views/clinic/WorksheetExplorer'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic', 'p:super-admin'], label: 'Worksheet Explorer', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'worksheet-explorer' } }
  },
  {
    path: '/worksheet-explorer/:worksheetLabel/:worksheetKey',
    name: 'WorksheetExplorerDetail',
    props: true,
    component: () => import('@/views/clinic/WorksheetExplorerDetail'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic', 'p:super-admin'], label: 'Worksheet Detail', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'worksheetLabel' } }
  },
  {
    path: '/video-explorer',
    name: 'WeMoveExplorer',
    props: true,
    component: () => import('@/views/clinic/VideoExplorer'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic', 'p:super-admin'], label: 'Video Explorer', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'video-explorer' } }
  },
  {
    path: '/video-explorer/:targetName/:targetKey',
    name: 'VideoDetail',
    props: true,
    component: () => import('@/views/VideoDetail'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic', 'p:super-admin'], label: 'Video Detail', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'targetName' } }
  },
  {
    path: '/patient/patient-dashboard/:targetName/:targetKey',
    name: 'PatientVideoDetail',
    props: true,
    component: () => import('@/views/VideoDetail'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:patient', 'm:clinic'], label: 'Video Detail', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'targetName' } }
  },
  {
    path: '/clinic/clinic-home',
    name: 'ClinicDashboard',
    props: true,
    component: () => import('@/views/clinic/ClinicDashboard'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Clinic Dashboard', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'clinic-dashboard' } }
  },
  {
    path: '/clinic/notifications',
    name: 'ClinicNotificationManager',
    props: true,
    component: () => import('@/views/clinic/Notifications/ClinicNotificationManager'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Notification Manager', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'notification-manager' } }
  },
  {
    path: '/clinic/notifications/create',
    name: 'ClinicNotificationBuilder',
    props: true,
    component: () => import('@/views/clinic/Notifications/ClinicNotificationBuilder'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Create Notification', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'create-notification' } }
  },
  // {
  //   path: '/clinic/clinic-patients',
  //   name: 'ClinicPatients',
  //   props: true,
  //   component: () => import('@/views/clinic/ClinicPatients'),
  //   beforeEnter: authGuard,
  //   meta: { needsPermission: ['m:clinic'], label: 'Patient Roster', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'patient-roster' } }
  // },
  // {
  //   path: '/clinic/patient-roster',
  //   name: 'PatientRoster',
  //   props: true,
  //   component: () => import('@/views/clinic/PatientRoster'),
  //   beforeEnter: authGuard,
  //   meta: { needsPermission: ['m:clinic'], label: 'Patient Roster', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'patient-roster' } }
  // },
  {
    path: '/clinic/patient-roster',
    name: 'PatientRoster',
    props: true,
    component: () => import('@/views/clinic/PatientRoster'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Patient Roster', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'patient-roster' } }
  },
  {
    path: '/clinic/patient-roster-additions',
    name: 'PatientRosterAdditions',
    props: true,
    component: () => import('@/views/clinic/PatientRosterAdditions.vue'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Patient Roster', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'patient-roster' } }
  },
  // {
  //   path: '/clinic/therapist-roster',
  //   name: 'ClinicTherapists',
  //   props: true,
  //   component: () => import('@/views/clinic/TherapistManager'),
  //   beforeEnter: authGuard,
  //   meta: { needsPermission: ['m:clinic'], label: 'Therapists', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'therapist-roster' } }
  // },
  // {
  //   path: '/clinic/therapist-roster/:therapistName/:therapistId',
  //   name: 'TherapistDetail',
  //   props: true,
  //   component: () => import('@/views/clinic/Therapist'),
  //   beforeEnter: authGuard,
  //   meta: { needsPermission: ['m:clinic'], label: 'Therapist Detail', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'therapistName' } }
  // },
  {
    path: '/clinic/clinic-roster',
    name: 'ClinicRoster',
    props: true,
    component: () => import('@/views/clinic/ClinicRoster'),
    beforeEnter: authGuard,
    // good, all clinicians should be able to see this + super-admin
    meta: { needsPermission: ['m:clinic'], label: 'Clinic Roster', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'clinic-roster' } }
  },
  {
    path: '/clinic/clinic-roster/:clinicianName/:clinicianId/account-management',
    name: 'ClinicianAccountManagement',
    props: true,
    component: () => import('@/views/clinic/ClinicianAccountManagement.vue'),
    beforeEnter: authGuard,
    // good, all clinicians should be able to see this (super-admin must impersonate to get here) -- individual controls should be restricted
    meta: { needsPermission: ['m:clinic', 'm:admin'], label: 'Clinician Acct Mgmt', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: true, key: 'clinicianName' } }
  },
  {
    path: '/clinic/quick-start',
    name: 'QuickStart',
    props: true,
    component: () => import('@/views/clinic/QuickStart'),
    beforeEnter: authGuard,
    // good, all clinic and super
    meta: { needsPermission: ['m:clinic'], label: 'Quick Start', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, key: 'quick-start' } }
  },
  {
    path: '/clinic/clinic-home/patient-detail/:patientName/:patientId',
    name: 'PatientTherapyDashboard',
    props: true,
    component: () => import('@/views/clinic/PatientTherapyDashboard'),
    beforeEnter: authGuard,
    // only needs clinic
    meta: { needsPermission: ['m:clinic'], label: 'Patient Detail', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, label: 'patient: ', key: 'patientName' } }
  },
  {
    path: '/clinic/clinic-home/patient-overview/:patientName/:patientId',
    name: 'ClinicPatientOverview',
    props: true,
    component: () => import('@/views/clinic/ClinicPatientOverview'),
    beforeEnter: authGuard,
    // only needs clinic
    meta: { needsPermission: ['m:clinic'], label: 'Patient Overview', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: true, label: 'patient: ', key: 'patientName' } }
  },
  {
    path: '/clinic/clinic-home/patient-overview/:patientName/:patientId/patient-notes',
    name: 'ClinicPatientNotes',
    props: true,
    component: () => import('@/views/clinic/ClinicPatientNotes'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Patient Notes', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'patient-notes' } }
  },
  {
    path: '/clinic/clinic-home/patient-overview/:patientName/:patientId/account-management',
    name: 'ClinicPatientManagement',
    props: true,
    component: () => import('@/views/clinic/ClinicPatientManagement'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Patient Acct Mgmt', stickyNav: true, requiresAuth: false, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'account-management ' } }
  },
  {
    path: '/clinic/clinic-home/patient-overview/:patientName/:patientId/manage-patient-worksheets',
    name: 'PatientWorksheetManagement',
    props: true,
    component: () => import('@/views/clinic/PatientWorksheetManagement'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Manage Worksheets', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'manage-patient-worksheets' } }
  },
  {
    path: '/clinic/clinic-home/patient-overview/:patientName/:patientId/manage-patient-videos',
    name: 'PatientMotionManagement',
    props: true,
    component: () => import('@/views/clinic/PatientMotionManagement'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Manage Videos', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'manage-patient-videos' } }
  },
  {
    path: '/clinic/clinic-home/patient-overview/:patientName/:patientId/manage-patient-activities',
    name: 'PatientProgramManagement',
    props: true,
    component: () => import('@/views/clinic/PatientActivityManagement'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:clinic'], label: 'Manage Activities', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'manage-patient-activities' } }
  },
  {
    path: '/patient/patient-dashboard',
    name: 'PatientDashboard',
    props: true,
    component: () => import('@/views/clinic/PatientDashboard'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:patient', 'm:clinic'], label: 'Patient Dashboard', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'patient-dashboard' } }
  },
  {
    path: '/patient/patient-gameplay',
    name: 'PatientGameplay',
    props: true,
    component: PagePlaceholder,
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:patient', 'm:clinic', 'p:super-admin'], label: 'Patient Gameplay', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'patient-gameplay' } }
  },
  {
    path: '/play-activity-configuration/:gameSessionType/:gameConfigurationId',
    name: 'PlayActivityConfiguration',
    props: true,
    component: () => import('@/views/PlayActivityConfiguration'),
    beforeEnter: authGuard,
    meta: { needsPermission: ['m:patient', 'm:clinic', 'p:super-admin'], label: 'Activity Config', stickyNav: true, requiresAuth: true, transitionPage: 'fade', breadcrumb: { dynamic: false, label: 'play-activity-configuration' } }
  },
  {
    path: '*',
    name: 'NotFound404',
    component: () => import('@/views/NotFound404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// router.beforeEach((to, from, next) => {
//   if ((to.path.includes('/clinic/') || (to.path.includes('/admin/'))) && store.state.user.isPatient) next({ name: 'PatientDashboard' })
//   else next()
// })

export default router
