<script>
import { mapGetters } from 'vuex'
import { demo } from '@/mixins/demo'
import IconBase from '@/components/_common/icons/IconBase'
import LoginIcon from '@/components/_common/icons/library/LoginIcon'
import brochureHeader from '@/graphql/query/brochureHeader.graphql'
export default {
  name: 'TheNav',
  components: {
    IconBase,
    LoginIcon
  },
  mixins: [demo],
  computed: {
    ...mapGetters({
      libraryInfo: 'brochure/libraryInfo',
      workingLibrary: 'ovb/workingLibrary',
      // guestUserInfo: 'brochure/guestUserInfo',
      licensePlans: 'brochure/licensePlans',
      pricingTag: 'brochure/pricingTag',
      isHomeProgram: 'brochure/isHomeProgram',
      isBrainRehab: 'brochure/isBrainRehab'
    }),
    libraryOptions () {
      return this.libraryInfo.map(o => {
        return {
          ...o,
          value: o.id,
          selected: (o.id === this.workingLibrary.id),
          label: o.id === 'vision' ? 'Vision Therapy' : `${o.name} Therapy`
        }
      })
    },
    showBernellNav () {
      // const partnerCode = this.pricingTag ? this.pricingTag.toLowerCase() : null
      const partnerCode = (typeof this.pricingTag === 'string') ? this.pricingTag.toLowerCase() : null
      return (partnerCode === 'bernell')
    },
    hideTopNav () {
      return (this.$route.path === '/brain-rehab-home')
    }
  },
  methods: {
    loginWithRedirect () {
      window.location = '/login' // this.$router.push('/authenticated')
    }
  },
  created () {
    this.$eventHub.$on('SHOW_FREE_TRIAL', this.onShowFreeTrial)
    // if (!this.guestUserInfo) {
    //   const guestUserInfo = {
    //     email: null,
    //     phone: null,
    //     company: null,
    //     lastName: null,
    //     firstName: null
    //   }
    //   this.$store.commit('brochure/SET_GUEST_USER', guestUserInfo)
    // }
  },
  apollo: {
    plans: {
      query: brochureHeader,
      loadingKey: 'loading',
      update (data) {
        const licensePlans = {
          vision: data.vision,
          occupational: data.occupational
        }
        this.$store.dispatch('brochure/setLicensePlans', licensePlans)
        this.$store.dispatch('brochure/setLibraryInfo', data.libraryInfo.libraries)
        if (!this.workingLibrary) {
          const defaultLib = data.libraryInfo.libraries.find(o => o.id === 'vision')
          this.$store.dispatch('SET_WORKING_LIBRARY', defaultLib)
        }
      }
    }
  }
}
</script>

<template>
  <b-navbar
    class="the-nav"
    :shadow="false"
    :spaced="false"
    :fixed-top="true"
    :centered="true"
    v-if="!hideTopNav"
  >
    <template #brand>
      <template v-if="!isBrainRehab">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
            class="ovb-logo"
            :src="require('@/assets/promo/img/OVB_logo_gray.svg')"
            alt="Our Visual Brain home"
          >
        </b-navbar-item>
      </template>
      <template v-else>
        <b-navbar-item tag="router-link" :to="{ path: '/brain-rehab' }">
          <img
            class="ovb-logo"
            :src="require('@/assets/promo/img/OVB_logo_gray.svg')"
            alt="Our Visual Brain: Brain Rehab home"
          >
        </b-navbar-item>
      </template>
    </template>
    <template #start>
      <b-navbar-item href="#">
        <template v-if="!isBrainRehab">
          <router-link
            exact-path
            class="is-family-semibold text-outline-light text-outline"
            :to="{ name: 'HomeView', params: {stickyNav: true}}"
          >
            Home
          </router-link>
        </template>
        <template v-else>
          <router-link
            exact-path
            class="is-family-semibold text-outline-light text-outline"
            :to="{ name: 'BrainRehab', params: {stickyNav: true}}"
          >
            Home
          </router-link>
        </template>
      </b-navbar-item>
      <b-navbar-item
        href="#"
        v-if="!isHomeProgram && !isBrainRehab"
      >
        <router-link
          exact
          class="is-family-semibold text-outline-light text-outline"
          :to="{ name: 'FeaturesView', params: {stickyNav: true}}"
        >Features</router-link>
      </b-navbar-item>
      <b-navbar-item
        v-if="!isBrainRehab"
        href="#"
      >
        <router-link
          class="is-family-semibold text-outline-light text-outline"
          :to="{ name: 'ActivitiesView', params: {stickyNav: true}}"
        >Activities</router-link>
      </b-navbar-item>
      <b-navbar-item
        v-if="showBernellNav"
        href="#"
      >
        <router-link
          exact-path
          class="is-family-semibold text-outline-light text-outline"
          :to="{ path: '/partners/bernell', params: {stickyNav: true}}"
        >Bernell Plans</router-link>
      </b-navbar-item>
      <b-navbar-item
        v-if="!showBernellNav && !isHomeProgram && !isBrainRehab" href="#"
      >
        <router-link
          class="is-family-semibold text-outline-light text-outline"
          :to="{ name: 'PricingView', params: {stickyNav: true}}"
        >Plans</router-link>
      </b-navbar-item>
      <b-navbar-item
        href="#"
        v-if="!isBrainRehab"
      >
        <router-link
          class="is-family-semibold text-outline-light text-outline"
          :to="{ name: 'AboutView', params: {stickyNav: true}}"
        >About</router-link>
      </b-navbar-item>
      <b-navbar-item href="#">
        <router-link
          class="is-family-semibold text-outline-light text-outline"
          :to="{ name: 'ContactView', params: {stickyNav: true}}"
        >Contact</router-link>
      </b-navbar-item>
      <b-navbar-item
        href="#"
        v-if="!isBrainRehab"
      >
        <router-link
          class="is-family-semibold text-outline-light text-outline"
          :to="{ name: 'StoreView', params: {stickyNav: true}}"
        >Bookstore</router-link>
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div" class="login-container">
        <a
          class="button-login"
          @click="loginWithRedirect"
        >
          <icon-base :width="32" :height="32">
            <login-icon />
          </icon-base>
          <span class="is-family-semibold">Login</span>
        </a>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<style lang="scss" scoped>
.ovb-logo {
  display: block;
  min-width: 13rem;
  @include tablet {
    min-width: 13rem;
  }
  @include desktop {
    min-width: 14rem;
  }
}
.button-login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  &:hover {
    background-color: $white;
  }
}
</style>
<style lang="scss" >
.navbar.the-nav {
  background-color: $white-50;
  backdrop-filter: blur(8px);
  z-index: 99;
  min-height: auto;
  .navbar-brand .navbar-item {
    padding-left: 1rem;
  }
  .navbar-item {
    color: $text;
    font-size: $size-5;
    &:hover {
      filter: saturate(3);
    }
    &.login-container {
      padding-right: 1rem;
    }
  }
  .text-outline {
    filter: url('#outline');
  }
  .navbar-burger {
    width: 4rem;
    height: 4rem;
  }
  .navbar-brand, .navbar-tabs {
    min-height: auto;
  }
  .navbar-item img {
    max-height: none;
  }
  .router-link {
    &-active {
      color: $watermelon;
    }
  }
}
</style>
