<script>
import { mapGetters } from 'vuex'
import TheTopBar from '@/components/TheTopBar'
import MainNavMenu from '@/components/MainNavMenu/MainNavMenu'
import MainNavAvatar from '@/components/MainNavAvatar/MainNavAvatar'
import TheFooter from '@/components/TheFooter'
import SuperAdminTools from '@/components/admin/superAdminTools/SuperAdminTools'
import TheNav from '@/components/PromoSite/TheNav'
import axios from 'axios'
import eventBus from '@/eventBus'
import SupportSection from '@/components/MainNavAvatar/SupportSection.vue'
// import PromoLinksOverlay from '@/components/PromoSite/PromoLinksOverlay.vue'

export default {
  name: 'App',
  components: {
    TheNav,
    TheTopBar,
    MainNavMenu,
    MainNavAvatar,
    SuperAdminTools,
    TheFooter,
    // PromoLinksOverlay,
    SupportSection
  },
  data () {
    return {
      showNavPanel: true,
      scrollPosition: 0,
      refreshKey: 0
    }
  },
  computed: {
    ...mapGetters({
      sidenavState: 'application/sidenavState',
      clipboardState: 'application/clipboardState',
      pricingTag: 'brochure/pricingTag',
      userNotifications: 'notifications/userNotifications',
      isHomeProgram: 'brochure/isHomeProgram',
      isTodos: 'brochure/isTodos',
      isBrainRehab: 'brochure/isBrainRehab',
      links: 'helpLinks/links'
    }),
    showBernellLink () {
      // fix for null pricing tag
      const partnerCode = (typeof this.pricingTag === 'string') ? this.pricingTag.toLowerCase() : null
      return ((this.$route.name !== 'PartnerView') && (partnerCode === 'bernell'))
    },
    showFreeTrial () {
      return ((this.$route.name !== 'BrochureFreeTrial') && (this.isBrainRehab === false) && (this.isHomeProgram === false) && (this.isTodos === false))
    },
    showFreeTrialEsp () {
      return ((this.$route.name !== 'BrochureFreeTrial') && (this.isHomeProgram === false) && (this.isTodos === true))
    },
    userInfo () {
      return this.$store.state.user.userInfo
    },
    emailHelp () {
      return this.$store.state.user.supportEmail
    },
    currentYear () {
      return this.$dateFns.getYear(new Date())
    },
    showSupport () {
      return this.$userHasPermission('p:app-tenant-scope')
    },
    recentException () {
      return this.$store.state.app.recentException
    },
    showClipboard () {
      return (this.clipboardState === 'ACTIVE')
    },
    isMobile () {
      return ((this.$mq === 'mobile') || (this.$mq === 'tablet'))
    },
    currentRoute () {
      return this.$router.currentRoute
    },
    sidebarMobile () {
      // if nav is already open, leave it open, else hide
      let sbMobile = 'hide'
      if (this.sidebarPosition === 'fixed') {
        if (this.sidenavState === 'ACTIVE') {
          sbMobile = 'show'
        }
      }
      return sbMobile
    },
    sidebarPosition () {
      let positioning
      switch (this.$mq) {
        case 'mobile':
          positioning = 'fixed'
          break
        case 'tablet':
          positioning = 'fixed'
          break
        case 'desktop':
          positioning = 'static'
          break
        case 'widescreen':
          positioning = 'static'
          break
        case 'fullhd':
          positioning = 'static'
          break
        default:
      }
      return positioning
    },
    expandNavOnHover () {
      return true
    },
    reduceNav () {
      return false
    },
    showPlayOverlay () {
      return location.search.includes('state=play')
    },
    // showNotifications () {
    //   const isSuperAdmin = this.userInfo.permissions ? this.userInfo.permissions.includes('p:super-admin') : false
    //   return (this.isLoggedIn && !isSuperAdmin)
    // },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    },
    domain () {
      return window.location.origin
    }
  },
  watch: {
    recentException () {
      if (this.recentException) {
        if (this.recentException === 'LOGOUT') {
          this.$store.dispatch('clearRecentException')
          this.forceLogout('Your session has expired.  Please login again to continue.')
        } else if (this.recentException === 'USER_EXISTS') {
          this.$buefy.dialog.alert({
            message: 'A user with this email already exists',
            trapFocus: true
          })
          this.$store.dispatch('clearRecentException')
        } else if (this.recentException === 'PREVIOUS_FREE_TRIAL') {
          this.$buefy.dialog.alert({
            message: 'Your Company or email address has been previously used for a Free Trial or OVB account.',
            trapFocus: true
          })
          this.$store.dispatch('clearRecentException')
        } else {
          this.$buefy.dialog.alert({
            message: `A Server Error Has Occurred ${this.$userHasPermission('p:super-admin') ? `\n\n${this.$store.state.app.recentException}` : ''}`,
            trapFocus: true
          })
          this.$store.dispatch('clearRecentException')
        }
      }
    }
  },
  methods: {
    handleHomeProgramPurchase () {
      eventBus.$emit('PURCHASED_HOME_PROGRAM')
    },
    handleTodosPurchase () {
      eventBus.$emit('PURCHASED_TODOS_PROGRAM')
    },
    onFreeTrial () {
      this.$router.push('/free-trial')
    },
    onBernellLink () {
      this.$router.push('/partners/bernell')
    },
    loginWithRedirect () {
      window.location = '/login' // this.$router.push('/authenticated')
    },
    onOpenSideNav () {
      this.showNavPanel = !this.showNavPanel
    },
    toggleSideNav () {
      let payload
      switch (this.sidenavState) {
        case 'ACTIVE':
          payload = 'HIDDEN'
          break
        case 'HIDDEN':
          payload = 'ACTIVE'
          break
        default:
      }
      this.$store.dispatch('application/setSidenavState', payload)
    },
    closeClipboard () {
      this.$store.dispatch('application/setClipboardState', 'HIDDEN')
    },
    forceLogout (message) {
      this.$buefy.dialog.alert({
        message: message,
        trapFocus: true,
        onConfirm: () => {
          axios.get('/post-logout')
          this.$store.dispatch('clearRecentException')
          this.$store.dispatch('brochure/setGuestUser', null)
          this.$store.dispatch('user/logout')
          this.$store.dispatch('app/logout')
          this.$store.dispatch('RESET_OVB')
            .then(() => {
              this.$router.push({ name: 'PreLogout' })
            })
        }
      })
    }
  },
  created () {
    if (window.location.origin === 'https://ourvisualbrain.com') {
      // there must be a better way found for this - redirecting at dns preferred
      const tgtPath = window.location.pathname
      window.location.href = 'https://www.ourvisualbrain.com' + tgtPath
    } else {
      window.addEventListener('scroll', () => {
        this.scrollPosition = window.top.scrollY
      })
    }
    // if (this.$route.name === 'HomeProgramView') {
    //   this.$store.dispatch('brochure/setIsHomeProgram', true)
    // }
  },
  destroyed () {
    window.removeEventListener('scroll', () => {
      this.scrollPosition = window.top.scrollY
    })
  }
}
</script>

<template>
  <div
    id="app"
    :class="{'side-nav-active': sidenavState === 'ACTIVE'}"
  >
    <div
      v-if="showPlayOverlay"
      id="play-overlay"
      :class="{ 'is-active': showPlayOverlay }"
    >
      <div class="overlay-logo">
        <img
          src="/img/ovb_logo.svg"
          aria-hidden="true"
          alt="Our Visual Brain logo"
        />
        <div
          class="overlay-loading-spinner mt-5"
        >
          <div class="loader"></div>
        </div>
        <div
          class="has-text-white is-size-5 is-family-light mt-2"
        >
          Preparing Your Activity...
        </div>
      </div>
    </div>
    <template
      v-if="!isLoggedIn"
    >
      <the-nav />
      <div
        v-if="showFreeTrial"
        @click="onFreeTrial"
        class="free-demo-bar"
      >
        <div
          class="free-demo-text text-outline-dark"
        >
          Start Your Free Trial!
        </div>
      </div>
      <a
        v-if="isHomeProgram"
        href="https://square.link/u/aYDfxMfZ"
        target="_blank"
        @click="handleHomeProgramPurchase"
        class="home-program-bar"
      >
        <div
          class="home-program-text text-outline-dark"
        >
          Buy Now!
        </div>
      </a>
      <a
        v-if="showFreeTrialEsp"
        @click="onFreeTrial"
        class="home-program-bar"
      >
        <div
          class="home-program-text text-outline-dark"
        >
          Prueba gratuita
        </div>
      </a>
      <div
        v-if="showBernellLink" class="bernell-link"
        @click="onBernellLink"
      >
        <span class="bernell-link-text text-outline-dark">Bernell Plans</span>
        <span class="icon is-small bernell-link-text">
            <i class="mdi mdi-arrow-right"></i>
          </span>
      </div>
    </template>
    <div
      v-show="!showPlayOverlay"
      class="site-layout"
      :class="{'side-nav-active': sidenavState === 'ACTIVE'}"
    >
      <aside
        v-if="isLoggedIn"
        :class="{'active': sidenavState === 'ACTIVE'}"
        class="sidebar-panel"
      >
        <div class="sidebar-content">
          <div
            class="sidebar-top"
            :class="{'mb-6': !showSupport}"
          >
            <main-nav-avatar />
            <button
              aria-expanded="false"
              class="sidenav-toggle button"
              @click="toggleSideNav"
              aria-label="Hide side navigation"
            >
                  <span class="icon is-large">
                    <i
                      class="mdi mdi-backburger mdi-36px"
                      aria-hidden="true"
                    />
                  </span>
            </button>
          </div>
          <support-section
            v-if="showSupport"
            class="mb-5"
          />
          <main-nav-menu />
        </div>
      </aside>
      <div
        :class="{'active': sidenavState === 'ACTIVE'}"
        class="sidebar-mask"
        @click="toggleSideNav"
      />
      <div
        class="view-screen"
      >
        <header
          v-if="isLoggedIn"
          class="app-header"
          :class="{'is-fixed': scrollPosition > 0, 'is-logged-in': isLoggedIn }"
        >
          <the-top-bar
            @OPEN_SIDE_NAVIGATION_PANEL="onOpenSideNav"
          />
        </header>
        <div
          class="content-wrapper"
        >
          <main
            class="content-area"
            :class="{'container': isLoggedIn, 'is-logged-in': isLoggedIn}"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <div
                :key="$route.fullPath"
                class="page-content"
                :class="{'page-margin': isLoggedIn}"
              >
                <div id="floating-actions"></div>
<!--                <promo-links-overlay-->
<!--                  :key="refreshKey"-->
<!--                  v-if="!isLoggedIn"-->
<!--                />-->
                <router-view
                  :class="{'is-logged-in': isLoggedIn }"
                />
              </div>
            </transition>
          </main>
        </div>
        <the-footer />
      </div>
      <aside
        v-if="isLoggedIn"
      >
        <b-sidebar
          id="main-right-sidebar"
          :fullheight="true"
          :right="true"
          position="fixed"
          v-model="showClipboard"
          :can-cancel="[]"
        >
          <template>
            <super-admin-tools />
          </template>
        </b-sidebar>
      </aside>
    </div>
    <svg
      height="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter id="outline">
          <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="4"></feMorphology>

          <feFlood flood-color="#F3EED9" flood-opacity="0.15" result="PINK"></feFlood>
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
          <feGaussianBlur in="OUTLINE" stdDeviation="2" result="FUZZYOUTLINE" />
          <feMerge>
            <feMergeNode in="FUZZYOUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
    </svg>
<!--    <div class="test-info-overlay">{{ $route.name }}</div>-->
  </div>
</template>

<style lang="scss">
  @import "assets/scss/_common";
  body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .test-info-overlay {
    position: absolute;
    padding: 1rem;
    background-color: red;
    color: #fff;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  .notifications-container {}
  #app {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100vh;
    @include desktop {
      &.side-nav-active {
        footer.ovb-footer {
          padding: 2rem 1rem 1rem 1rem;
        }
      }
    }
    .bernell-link {
      position: fixed;
      top: $topBarHeight;
      right: 0;
      z-index: 75;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      padding: 0.5rem 1rem;
      cursor: pointer;
      width: 100%;
      &:hover {
        filter: saturate(1.5);
      }
      @include tablet {
        top: 8rem;
        width: auto;
        bottom: auto;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
      @include desktop {
        top: 7.5rem;
        width: auto;
      }
      .bernell-link-text {
        font-size: 1.15rem;
        font-family: $family-semibold;
        color: $white;
      }
    }
    .free-demo-bar {
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 70;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $watermelon;
      padding: 0.5rem 1rem;
      cursor: pointer;
      border-bottom-left-radius: 0.25rem;
      width: 100%;
      &:hover {
        filter: saturate(1.5);
      }
      @include tablet {
        top: 4rem;
        width: auto;
        bottom: auto;
      }
      @include desktop {
        top: 3.6rem;
        width: auto;
      }
      .free-demo-text {
        font-size: 1.15rem;
        font-family: $family-semibold;
        color: #fff;
      }
    }
    .home-program-bar {
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 70;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $watermelon;
      padding: 0.5rem 1rem;
      cursor: pointer;
      border-bottom-left-radius: 0.25rem;
      width: 100%;
      &:hover {
        filter: saturate(1.5);
      }
      @include tablet {
        top: 4rem;
        width: auto;
        bottom: auto;
      }
      @include desktop {
        top: 3.6rem;
        width: auto;
      }
      .home-program-text {
        font-size: 1.5rem;
        font-family: $family-semibold;
        color: #fff;
      }
    }
  }
  .view-screen {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .site-layout {
    display: flex;
    flex-grow: 1;
    .app-header {
      display: flex;
      width: 100vw;
       height: $topBarHeight;
      -webkit-transition: all 0.45s;
      transition: all 0.45s ease-in-out;
      &.is-fixed {
        position: fixed;
        z-index: 60;
        box-shadow: $shadow-3dp;
        width: 100vw;
      }
    }
    &.side-nav-active {
      .app-header {
        @include desktop {
          width: calc(100vw - #{$navPanelWidth});
        }
      }
    }
  }
  .content-wrapper {
    flex-grow: 1;
    display: flex;
  }
  main.content-area {
    display: flex;
    &:not(.is-logged-in) {
      flex-grow: 1;
    }
  }
  .page-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &.is-logged-in {
      margin: 1rem;
    }
  }
  .sidebar-mask {
    display: none;
    &.active {
      display: flex;
      background-color: transparent;
      flex: 1;
      width: 100%;
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 70;
      @include desktop {
        display: none;
      }
    }
  }
  .sidebar-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: $navPanelWidth;
    max-width: $navPanelWidth;
    min-width: $navPanelWidth;
    background-color: $text;
    margin-left: -$navPanelWidth;
    -webkit-transition: all 0.45s;
    transition: all 0.45s ease-in-out;
    @include mobile {
      position: fixed;
      min-height: 100vh;
      z-index: 80;
      &.active {
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, .5);
      }
    }
    @include tablet {
      position: fixed;
      min-height: 100vh;
      z-index: 80;
      &.active {
        box-shadow: 0 0 12px 2px rgba(0, 0, 0, .5);
      }
    }
    @include desktop {
      position: relative;
      z-index: 0;
      &.active {
        box-shadow: none;
      }
    }
    @include fullhd {
      position: relative;
      z-index: 0;
      &.active {
        box-shadow: none;
      }
    }
    @include widescreen {
      position: relative;
      z-index: 0;
      &.active {
        box-shadow: none;
      }
    }
    &.active {
      margin-left: 0;
    }
    .sidebar-top {
      display: flex;
    }
    .sidebar-content {
      height: 100vh;
      background-color: $text;
      width: $navPanelWidth;
      max-width: $navPanelWidth;
      min-width: $navPanelWidth;
      position: fixed;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    button.button.sidenav-toggle {
      background-color: transparent;
      border: none;
      border-radius: 0;
      margin-top: 0.5rem;
      i {
        color: $primary;
      }
    }
    hr.nav-section-separator {
      height: 1px;
      opacity: .25;
      margin: 2rem 0 0 0;
    }
  }
  #main-right-sidebar.b-sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .sidebar-content.is-fixed.is-right {
      top: $topBarHeight;
      box-shadow: 5px 10px 13px 3px rgba(10, 10, 10, 0.1);
    }
    .close-button {
      margin: 1rem;
    }
  }
  .brochure-nav {
    position: absolute;
    top: 90px;
    width: 100%;
    z-index: 400;
    &.is-sticky {
      position: fixed;
      background-color: #fff;
      top: 0;
    }
  }
  .copyright-notice {
    margin-top: 1rem;
    flex: 1;
    font-size: $size-6;
  }
  //footer.ovb-footer {
  //  background-color: $gray-darkest;
  //  padding: 2rem 0 1rem 0;
  //  display: flex;
  //  flex-direction: column;
  //  color: $gray-light;
  //}
  //.ovb-text-logo {
  //  width: 15rem;
  //  opacity: .5;
  //}
  //.ovb-footer-content {
  //  flex: 1;
  //  .footer-separator {
  //    border-top: 0.5px solid $gray-light;
  //    opacity: 0.25;
  //  }
  //  .content-row {
  //    padding: 0 1rem;
  //    display: flex;
  //    flex-direction: column;
  //    flex-wrap: wrap;
  //    justify-content: space-between;
  //    @include tablet {
  //      flex-direction: row;
  //    }
  //    @include desktop {
  //      padding: 0;
  //    }
  //  }
  //  .nav-list {
  //    display: flex;
  //    flex-direction: column;
  //    .nav-title {
  //      font-family: $family-semibold;
  //      font-size: 1.25rem;
  //      opacity: 0.5;
  //    }
  //    .footer-link {
  //      color: $gray-light;
  //      //font-family: $family-semibold;
  //      &:hover {
  //        text-decoration: underline;
  //      }
  //    }
  //  }
  //}

  #play-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 900;
    background-image: linear-gradient(to right top, $purple, $info );
    color: rgba(var(--text-color));
    overflow: hidden;
    transition: all 3s ease-in-out;
    &.is-active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .overlay-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 40rem;
      min-width: 20rem;
      img {
        display: block;
      }
    }
    .overlay-loading-spinner {
      position: relative;
      flex: 1;
      width: 3rem;
      height: 3rem;
      opacity: .3;
    }
    .loader {
      width: 48px;
      height: 48px;
      border: 5px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>
