<script>
import eventBus from '@/eventBus.js'
import BaseModal from '@/components/_common/modal/BaseModal'
import DeviceSelector from '@/components/clinic/DeviceSelector'
import BreadcrumbNavigation from '@/components/_common/BreadcrumbNavigation'
import { mapGetters } from 'vuex'
// import { sproutvideo } from '@/mixins/sproutvideo'
// we get the list of Sproutvideos here as it is common to both Admin and clinic logins
// import sproutvideoData from '@/graphql/query/sproutvideoData'
export default {
  name: 'TheTopBar',
  components: {
    BaseModal,
    DeviceSelector,
    BreadcrumbNavigation
  },
  // mixins: [sproutvideo],
  data () {
    return {
      resetNameKey: 0, // increment key to wipe new name value in device selector
      showDeviceSelector: false
    }
  },
  computed: {
    ...mapGetters({
      userDevice: 'user/userDevice',
      userInfo: 'user/userInfo',
      actualAppUserInfo: 'user/actualAppUserInfo',
      sidenavState: 'application/sidenavState',
      clipboardState: 'application/clipboardState',
      calibrationEnabled: 'user/calibrationEnabled',
      isHomePatient: 'user/isHomePatient'
    }),
    sidenavAriaLabel () {
      const labelFrag = 'side navigation'
      return this.sidenavState === 'ACTIVE' ? `Hide ${labelFrag}` : `Show ${labelFrag}`
    },
    deviceButtonLabel () {
      return this.userDevice ? 'Change' : 'Set Device'
    },
    deviceLabel () {
      return this.userDevice ? this.userDevice.label : 'no device set'
    }
  },
  watch: {
    // calibrationEnabled (val) {
    //   if (!val) {
    //     this.removeDevice()
    //   }
    // }
  },
  methods: {
    removeDevice () {
      // remove device as persistent cookie
      const expirationDate = new Date(0).toUTCString()
      let cookieString = ''
      const deviceString = 'ovbDeviceId=;'
      cookieString = deviceString + 'samesite=strict, Secure; path=/; expires=' + expirationDate
      document.cookie = cookieString
      // remove device in vuex users/setDevice
      this.$store.dispatch('user/setUserDevice', null)
    },
    onChangeDevice () {
      this.resetNameKey += 1
      this.showDeviceSelector = true
    },
    closeDeviceSelector () {
      this.showDeviceSelector = false
    },
    toggleSideNav () {
      let payload
      switch (this.sidenavState) {
        case 'ACTIVE':
          payload = 'HIDDEN'
          break
        case 'HIDDEN':
          payload = 'ACTIVE'
          break
        default:
      }
      this.$store.dispatch('application/setSidenavState', payload)
    },
    toggleClipboard () {
      let payload
      switch (this.clipboardState) {
        case 'ACTIVE':
          payload = 'HIDDEN'
          break
        case 'HIDDEN':
          payload = 'ACTIVE'
          break
        default:
      }
      this.$store.dispatch('application/setClipboardState', payload)
    },
    checkDeviceCookie (name) {
      const match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'))
      return match ? match[1] : null
    }
    // checkForNewUser (auth0Info) {
    //   const authData = auth0Info.data
    //   let newUser = false
    //   if (authData.length > 0) {
    //     const loginsCount = Number(authData[0].logins_count)
    //     newUser = (loginsCount < 10)
    //   }
    //   this.$store.dispatch('user/setIsNewUser', newUser)
    // }
  },
  mounted () {
    eventBus.$on('SET_USER_DEVICE', () => {
      this.showDeviceSelector = true
    })
    // const deviceCookie = this.checkDeviceCookie('OVB_Device')
    const deviceCookie = this.checkDeviceCookie('ovbDeviceId')
    if (deviceCookie) {
      this.$store.dispatch('user/setUserDevice', deviceCookie)
    }
  },
  beforeDestroy () {
    eventBus.$off('SET_USER_DEVICE')
  }
  // apollo: {
  //   // grabbing info that should have been passed by currentUser query on start
  //   appUserByIdMissingInfo: {
  //     query: appUserByIdMissingInfo,
  //     // variables: {
  //     //   id: this.userInfo.appUserId
  //     // },
  //     variables () {
  //       return {
  //         id: this.userInfo.appUserId
  //       }
  //     },
  //     skip () {
  //       return this.$route.path.includes('patient')
  //     },
  //     update (data) {
  //       this.checkForNewUser(data.appUserById.auth0Info)
  //       // const appTenantInfo = {
  //       //   name: data.appUserById.appTenantByAppTenantId.name,
  //       //   id: data.appUserById.appTenantByAppTenantId.id,
  //       //   subscription: data.appUserById.appTenantByAppTenantId.appTenantSubscriptionByAnchorSubscriptionId.licensePackByLicensePackId
  //       // }
  //       // this.$store.dispatch('application/setAppTenantInfo', appTenantInfo)
  //     },
  //     error (error) {
  //       console.error('Error retrieving appUser and appTenant: ', error)
  //     }
  //   }
  // }
}
</script>
<template>
  <div
    class="the-top-bar"
  >
    <div
      class="container top-bar-container"
    >
      <div
        class="top-bar-items"
      >
        <div
          class="top-bar-item"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <button
              v-if="sidenavState === 'HIDDEN'"
              aria-expanded="false"
              class="sidenav-toggle button"
              @click="toggleSideNav"
              aria-label="toggle game clipboard"
            >
              <span class="icon is-large">
                <i
                  class="mdi mdi-menu mdi-36px"
                  aria-hidden="true"
                />
              </span>
            </button>
          </transition>
        </div>
        <div
          class="breadcrumb-container"
          :class="{'nav-hidden': sidenavState === 'HIDDEN'}"
        >
          <breadcrumb-navigation />
        </div>
<!--        <div-->
<!--          class="top-bar-item"-->
<!--        >-->
<!--          <span class="is-family-semibold ml-4">{{ $mq }}</span>-->
<!--        </div>-->
        <div class="top-bar-right">
          <template v-if="calibrationEnabled">
            <!-- Mobile ------------------------- -->
            <div class="top-bar-item device-selection is-hidden-tablet">
              <div>
                <span class="has-text-gray-dark mr-1">Device:</span>
                <template v-if="userDevice">
                  <span class="is-family-semibold">{{ userDevice.name }}</span>
                </template>
                <template v-else>
                  <span class="has-text-danger is-family-semibold">None set</span>
                </template>
              </div>
              <div>
                <a @click="onChangeDevice">
                  [ {{ deviceButtonLabel }} ]
                </a>
              </div>
            </div>
            <!-- Tablet Plus ------------------------- -->
            <div class="top-bar-item device-selection is-hidden-mobile">
              <div>
                <span class="has-text-gray-dark mr-1">Device:</span>
                <template v-if="userDevice">
                  <span class="is-family-semibold">{{ userDevice.name }}</span>
                </template>
                <template v-else>
                  <span class="has-text-danger is-family-semibold">None set</span>
                </template>
              </div>
              <div>
                <a @click="onChangeDevice" class="ml-2">
                  [ {{ deviceButtonLabel }} ]
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <base-modal
      name="SelectDevice"
      :close-on-mask-click="true"
      :has-close-button="true"
      :show="showDeviceSelector"
      :has-footer="false"
      @CLOSE_MODAL="closeDeviceSelector"
    >
      <template #header>
        Select or Add your Device
      </template>
      <template #content>
        <div>
          <device-selector
            v-if="calibrationEnabled"
            :reset-name-key="resetNameKey"
            @CLOSE_MODAL="closeDeviceSelector"
          />
        </div>
      </template>
    </base-modal>
  </div>
</template>

<style lang="scss" scoped>
  .the-top-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    //padding: 0 2rem 0 0.5rem;
    background-color: $beige;
    button.button.clipboard-toggle {
      background-color: transparent;
      border: none;
      border-radius: 0;
      i {
        color: $primary;
      }
    }
    .top-bar-container {
      //background-color: #cb5fae;
      flex-grow: 1;
      margin: 0 auto;
      position: relative;
      width: auto;
    }
    .top-bar-items {
      display: flex;
      align-items: center;
      padding: 0 1rem;
    }
    .breadcrumb-container {
      display: inline-flex;
      align-items: center;
      margin-left: 0;
      transition: all 0.3s ease-in-out;
      &.nav-hidden {
        margin-left: 1rem;
      }
    }
    .top-bar-right {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
    .top-bar-item {
      display: inline-flex;
      align-items: center;
    }
    .device-selection {
      display: flex;
      flex-wrap: wrap;
      line-height: 1rem;
      justify-content: flex-end;
    }
  }
</style>
