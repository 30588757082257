/*
This handles the Help Links Overlay.  In order to have contextual buttons we need a place to store them.
When a view is loaded it will pass a JSON list of contextual buttons to this sore, where it will be added to the list of default buttons.

When a route name changes the HelpLinksOverlay component will check to see if the route name is included in the sored JSON.
If not, it will wipe the stored JSON and replace it with the default.
 */

// import router from '@/router'

const types = {
  SET_LINKS: 'SET_LINKS',
  RESET_LINKS: 'RESET_LINKS'
}

const getDefaultState = () => {
  return {
    links: [
      {
        label: 'QuickStart Guide',
        action: 'ROUTE',
        target: 'QuickStart',
        cssClass: 'button is-fullwidth is-outlined is-rounded',
        cssStyle: '',
        icon: '',
        iconPosition: '',
        routeParams: null,
        targetTour: null,
        targetStep: null,
        eventPayload: null
      },
      {
        label: 'FAQs',
        action: 'ROUTE',
        target: 'FaqView',
        cssClass: 'button is-fullwidth is-outlined is-rounded',
        cssStyle: '',
        icon: '',
        iconPosition: '',
        routeParams: null,
        targetTour: null,
        targetStep: null,
        eventPayload: null
      },
      {
        label: 'Schedule a Demo',
        action: 'HYPERLINK',
        target: 'https://calendar.app.google/KvFP69dU5k66Fen7A',
        cssClass: 'button is-fullwidth is-outlined is-rounded',
        cssStyle: '',
        icon: '',
        iconPosition: '',
        routeParams: null,
        targetTour: null,
        targetStep: null,
        eventPayload: null
      },
      {
        label: 'Play the Activities',
        action: 'ROUTE',
        target: 'ActivityExplorer',
        cssClass: 'button is-fullwidth is-outlined is-rounded',
        cssStyle: '',
        icon: '',
        iconPosition: '',
        routeParams: null,
        targetTour: null,
        targetStep: null,
        eventPayload: null
      },
      {
        label: 'Tech Support',
        action: 'ROUTE',
        target: 'HelpCenter',
        cssClass: 'button is-fullwidth is-outlined is-rounded',
        cssStyle: '',
        icon: '',
        iconPosition: '',
        routeParams: null,
        targetTour: null,
        targetStep: null,
        eventPayload: null
      }
    ]
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  links: state => state.links
}

// actions
const actions = {
  setLinks ({ commit }, payload) {
    // console.log('setLinks: ', payload)
    // console.log('payload.list: ', payload.list)
    commit(types.SET_LINKS, payload)
  },
  resetLinks ({ commit }) {
    commit(types.RESET_LINKS)
  }
}

// mutations
const mutations = {
  [types.SET_LINKS] (state, payload) {
    // console.log('setLinks: ', payload)
    // console.log('payload.list: ', payload.list)
    // console.log('router.route: ', router.currentRoute)
    // the "method" property says how to add the list:
    // REPLACE will replace the list
    // SPLICE will insert the payload.list at the location of "index"
    switch (payload.method.toUpperCase()) {
      case 'REPLACE':
        state.links = payload.list
        break
      case 'INSERT':
        state.links = insertLinks(payload.list, payload.position)
        // state.links = state.links.splice(payload.index, 0, ...payload.list)
        break
      default:
        state.links = payload.list
    }
  },
  [types.RESET_LINKS] (state) {
    state.links = getDefaultState().links
  }
}

function insertLinks (linkList, position) {
  return state.links.reduce((acc, val, index) => {
    if (index === position) acc.push(...linkList)
    acc.push(val)
    return acc
  }, [])
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
